import React, {useState, useEffect, useContext} from "react";
import {login} from "@/lib/auth";
import AppContext from "@/context/AppContext";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Link from "next/link";
import Router from "next/router";
import {ContentContainer} from "@/components/ContentContainer";
import TextFieldStyled from "@/components/TextFieldStyled";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import LinkStyled from "@/components/LinkStyled";
import {TextFieldPasswordStyled} from "@/components/TextFieldPasswordStyled";

function Login() {
    const DEFAULT_VALUES = {
        username: "",
        password: "",
    };
    const appContext = useContext(AppContext);
    const [formValues, setFormValues] = useState(DEFAULT_VALUES);

    useEffect(() => {
        if (appContext.isAuthenticated) {
            Router.push("/secure");
            // redirect if you're already logged in
        }
    }, [appContext.isAuthenticated]);

    const handleInputChanged = (event) => {
        const {name, value} = event.target;
        setFormValues({
            ...formValues,
            [name]: value,
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        login(formValues.username, formValues.password)
            .then((res) => {
                if (res.data.user) {
                    appContext.setUser(res.data.user);
                    appContext.setBand(null);
                }
            })
            .catch((error) => {
                console.error("ERROR", error);
                setFormValues({...formValues, ['password']: ''});
                appContext.setSnackbar({type: "error", content: error?.message[0]?.messages[0]?.message ?? "Es ist ein Fehler aufgetreten, bitte versuch es noch mal."});
            });
    };

    return (
        <React.Fragment>
            <ContentContainer headline="Login">
                <form onSubmit={handleSubmit}>
                    <Grid container alignItems="center" justify="center" direction="column">
                        <Grid item xs={12} sm={6}>
                            <TextFieldStyled
                                required={true}
                                type="email"
                                name="username"
                                label="E-Mail Adresse"
                                placeholder="E-Mail Adresse"
                                value={formValues.name}
                                onChange={handleInputChanged}
                            />
                            <TextFieldPasswordStyled
                                required={true}
                                name="password"
                                label="Passwort"
                                placeholder="Passwort"
                                value={formValues.password}
                                onChange={handleInputChanged}
                            />
                            <Button fullWidth variant="contained" color="secondary" type="submit">
                                Einloggen
                            </Button>
                            <Box sx={{ lineHeight: "1.425rem", fontSize: "0.875rem", textAlign: "center", mt: 0.5,}}>
                                <LinkStyled
                                    href="/request-password-reset"
                                    label="Passwort vergessen?"
                                />
                            </Box>
                            <Divider />
                            <Typography variant="h5" sx={{ mb: 1,}}>Neuer Account</Typography>
                            <Button href="/register" component={Link} fullWidth variant="contained" color="primary" type="submit">
                                Jetzt registrieren
                            </Button>
                            <Box sx={{ lineHeight: "1.425rem", fontSize: "0.875rem", textAlign: "center", mt: 0.5,}}>
                                <LinkStyled
                                    href="/netzwerk/ueber-uns"
                                    label="Warum bei showcase.nrw registrieren?"
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </form>
            </ContentContainer>
        </React.Fragment>
    );
}

export default Login;
